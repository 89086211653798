import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { numberFormatter } from './utils';

const formatInput = (raw) => {
  if (raw === '') return raw;
  if (raw.includes('.')) {
    const [intPart, decimalPart] = raw.split('.');
    return `${numberFormatter.format(Number(intPart))}.${decimalPart}`;
  }
  return numberFormatter.format(Number(raw));
};

export const CurrencyField = (props) => {
  const { onChange, value, clearField } = props;
  const [rawInput, setRawInput] = useState();

  useEffect(() => {
    if (clearField) setRawInput('');
  }, [clearField]);

  const handleCurrencyChange = (event) => {
    const { key } = event;
    let raw = rawInput || '';
    if (key === 'Backspace') {
      const { selectionStart, selectionEnd } = event.target;
      if (
        selectionStart !== null &&
        selectionEnd !== null &&
        selectionStart !== selectionEnd
      ) {
        const updatedValue =
          value.slice(0, selectionStart) + value.slice(selectionEnd);
        raw = updatedValue.replace(/[,]/g, '');
      } else {
        raw = raw.substring(0, raw.length - 1);
        if (raw[raw.length - 1] === '.') {
          raw = raw.substring(0, raw.length - 1);
        }
      }
    } else if (!raw.split('.')[1] || raw.split('.')[1].length < 2) {
      raw += key;
    }
    if (Number.isNaN(Number(raw))) return;
    onChange(formatInput(raw), raw);
    setRawInput(raw);
  };

  return (
    <TextField
      {...props}
      onChange={() => null}
      onKeyDown={handleCurrencyChange}
    />
  );
};
